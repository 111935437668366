<template>
  <div class="finance">
   <main-nav />
    <main-titlebar myclass="blueBar"/>


      <div class="grid-box-vscale">
        <p>Hier komen geld gerelateerde zaken</p>
      </div>

      <div class="row-line">Inkomsten<hr /></div>    


        <div class="form-group">
          <div class="col-2-title textAlignCenter">
            Mijn tegoed:
          </div>
          <div class="col-2 textAlignLeft ">
            &euro; 120,00
          </div>
        </div>



      <div class="trip-view-row">
        <div class="trip-view-col">
          <router-link to="/my/profile">
            <div class="default-button">Opwaarderen</div>
          </router-link>
        </div>
      </div>

      <div class="trip-view-row">&nbsp;</div>

   <div class="row-line">Laatste transacties<hr /></div>    




     <div class="trip-view-row">
        <p class="textAlignLeft">01-01-2022 : &euro; 15,75</p><br>
      </div>
      <div class="trip-view-row">
        <p class="textAlignLeft">01-02-2022 : &euro; 47,31</p>
      </div>



      <div class="trip-view-row">
        <div class="trip-view-col">
          <router-link to="/">
            <div class="default-button">Overzicht transacties</div>
          </router-link>
        </div>
      </div>


  </div>
</template>

<script>
export default {
  name: 'Home',
  methods: {},
  components: {
    'main-titlebar': require('@/components/titlebar.vue').default,
    'main-nav': require('@/components/mainmenu.vue').default,
  }
}
</script>
